@import "../helpers/scss/config";
.Rating {
  height: 100%;
  justify-content: center;
  align-content: center;
  text-align: center;
  & .greenColor {
    color: $primary-color;
  }
  & .garyColor {
    color: #707070;
  }
  & .userName {
    text-transform: uppercase;
  }
}
