// .full-width {
//   background-color: #fff;
//   margin-bottom: 20px;
//   border-left: 1px solid #e5e5e5;
//   border-right: 1px solid #e5e5e5;
//   border-bottom: 1px solid #e5e5e5;
//   border-radius: 5px;
// }

.home {
  padding-top: 120px;
}

#wrapper {
  overflow-x: hidden;
  flex-wrap: nowrap;
}

#sidebar-wrapper {
  // min-height: 100vh;
  margin-left: -11.5rem;
  transition: margin 0.25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

// #sidebar-wrapper .list-group {
//   width: 15rem;
// }

#page-content-wrapper {
  min-width: 100vw;
}

.sb-sidenav-toggled #wrapper #sidebar-wrapper {
  margin-left: 0 !important;
}

@media (min-width: 768px) {
  #wrapper {
    overflow-x: visible;
  }
  #sidebar-wrapper {
    margin-left: 0 !important;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  .sb-sidenav-toggled #wrapper #sidebar-wrapper {
    margin-left: -11.7rem;
  }
}

@media (max-width: 575.98px) {
  .home {
    padding-top: 110px;
  }
}

@media (min-width: 375.1px) and (max-width: 425px) {
  #sidebar-wrapper {
    margin-left: -13rem;
  }
}
