.offline {
  color: #a1a1a1;
}
// @import "../helpers/scss/config";

// .main-left-sidebar {
//   float: left;
//   margin-top: 100px;

//   & .user_profile {
//     float: left;
//     width: 100%;
//     background-color: #fff;
//     margin-bottom: 20px;
//     text-align: center;
//     // margin-top: -40px;

//     & .user-pro-img {
//       float: left;
//       width: 100%;
//       text-align: center;
//       margin-bottom: 28px;
//       margin-top: -95px;
//       position: relative;

//       & img {
//         -webkit-border-radius: 100px;
//         -moz-border-radius: 100px;
//         -ms-border-radius: 100px;
//         -o-border-radius: 100px;
//         border-radius: 100px;
//         float: none;
//         border: 5px solid #fff;
//         width: 180px;
//         height: 180px;
//       }
//       & .add-dp {
//         position: absolute;
//         top: 10px;
//         left: 60%;
//         & input[type="file"] {
//           opacity: 0;
//           padding: 10px 0 9px;
//           display: none;
//         }
//         & svg {
//           bottom: 0;
//           // font-size: 14px;
//           box-shadow: none;
//           position: relative;
//           border-radius: 50px;
//           width: 40px;
//           height: 40px;
//           text-align: center;
//           background: $primary-color;
//           padding: 12px;
//           color: #ffff;
//           left: -45px;
//           top: 145px;
//           &:hover {
//             font-size: 14px;
//             border: 2px solid #fff;
//             background: $primary-color;
//             padding: 11px;
//             cursor: pointer;
//           }
//         }
//       }
//     }
//     & .user_pro_status {
//       float: left;
//       width: 100%;
//       border-bottom: 1px solid #e5e5e5;
//       padding-bottom: 27px;

//       & h3 {
//         color: #000;
//         font-size: 24px;
//         font-weight: 600;
//         text-transform: capitalize;
//       }
//     }
//   }
// }
// .main-ws-sec {
//   & .nav-tabs {
//     margin-bottom: 45px;
//     margin-top: 20px;
//     border-bottom: 0;

//     & .nav-link {
//       border: none;
//       color: #b2b2b2;
//       font-weight: 400;
//       font-size: 16px;
//     }

//     & .nav-link.active,
//     &.nav-item.show .nav-link {
//       color: $primary-color;
//       font-weight: 600;
//       font-size: 16px;
//       background-color: transparent;
//       border: none;
//     }
//   }

//   & .tab-content > .tab-pane {
//     float: left;
//     width: 100%;
//     background-color: #fff;
//     padding: 30px 20px;
//     border-left: 1px solid #e4e4e4;
//     border-bottom: 1px solid #e4e4e4;
//     border-right: 1px solid #e4e4e4;
//     margin-bottom: 20px;
//   }

//   & .user-profile-ov {
//     & button.addExpert {
//       background-color: $primary-color;
//       border-color: $primary-color;
//       color: #fff;
//       float: right;
//       margin-bottom: 0.25em;
//     }
//     & ul {
//       float: left;
//       width: 100%;
//       margin: 0;
//       padding: 0;
//       & li {
//         // display: inline-block;
//         margin-bottom: 10px;
//         padding: 0 2px;
//         list-style: none;
//         border-bottom: 1px solid rgba(0, 0, 0, 0.1);
//         &:last-child {
//           margin-bottom: 0;
//           border-bottom: 0;
//         }
//         & h4 {
//           font-size: 16px;
//           color: #000;
//           font-weight: 600;
//           margin-bottom: 0px;
//         }
//         & small {
//           color: #686868;
//         }
//         & p {
//           font-size: 15px;
//           line-height: 24px;
//         }

//         // & span {
//         //   display: inline-block;
//         //   color: #333333;
//         //   font-size: 14px;
//         //   font-weight: 600;
//         //   background-color: #e5e5e5;
//         //   -webkit-border-radius: 30px;
//         //   -moz-border-radius: 30px;
//         //   -ms-border-radius: 30px;
//         //   -o-border-radius: 30px;
//         //   border-radius: 30px;
//         //   padding: 10px 15px;
//         // }
//       }
//     }
//   }
// }

// @media (max-width: 575.98px) {
//   .main-left-sidebar {
//     float: none;
//     margin-top: 120px;
//   }
//   .add-pic-box [type="file"] + label {
//     font-size: 12px;
//     padding: 6px 10px;
//   }
// }
