@import "../helpers/scss/config";

.TransactionsHistory {
  & .historyCard {
    background-color: $gray-color;
    padding: 10px;
    border-radius: 40px;
    border: 3px solid $gray-color;
    margin-bottom: 1rem;
    align-items: center;
  }

  & .add {
    color: green;
  }
  & .withdraw {
    color: red;
  }

  & small {
    color: gray;
  }
}
