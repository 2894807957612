@import "../helpers/scss/config";

.callPrice {
  background-color: $primary-color;
  border-radius: 20px;
  color: $light-color;
  display: inline-block;
  padding: 5px;
}

.profile-page {
  .m-t-5 {
    margin-top: 5px;
  }

  & .card {
    background: #f2fefb;
    margin-top: 30px;
    transition: 0.5s;
    // border: 0;
    border-radius: 0.5rem;
    border-color: transparent;
    display: inline-block;
    position: relative;
    width: 100%;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);
    & {
      .body {
        padding: 10px 20px;
        & a {
          font-size: 14px;
          color: #424242;
          font-weight: 400;
          text-decoration: none;
        }
        & h5 {
          color: $primary-color;
        }
      }
    }

    & .ReactStars {
      margin: 0 0 auto 0;
    }
  }
  & .profile-header {
    position: relative;

    & .profile-image img {
      border-radius: 50%;
      width: 43px;
      height: 44px;
      // width: 140px;
      // border: 3px solid #fff;
      // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }

    & .social-icon a {
      margin: 0 5px;
    }
  }

  & .profile-sub-header {
    min-height: 60px;
    width: 100%;
    & ul.box-list {
      display: inline-table;
      table-layout: fixed;
      width: 100%;
      background: #eee;
    }
    & ul.box-list li {
      border-right: 1px solid #e0e0e0;
      display: table-cell;
      list-style: none;
    }
    & ul.box-list li:last-child {
      border-right: none;
    }
    & ul.box-list li a {
      display: block;
      padding: 15px 0;
      color: #424242;
    }
  }
}
@media (max-width: 575.98px) {
  .profile-page {
    & .card {
      & .profile-image img {
        width: 57px !important;
        height: 57px !important;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .profile-page {
    & .card {
      margin-top: 15px;
      & .body {
        padding: 5px 10px;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .profile-page {
    & .card {
      & .profile-image img {
        width: 63px !important;
        height: 64px !important;
      }
    }
  }
}
