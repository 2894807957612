#notfound {
  position: relative;
  height: 100vh;

  & .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

.notfound {
  max-width: 767px;
  width: 100%;
  line-height: 1.4;
  padding: 0px 15px;
  margin: 0 auto;

  & .notfound-404 {
    position: relative;
    height: 200px;
    line-height: 150px;
    margin-bottom: 25px;
    & h1 {
      font-family: "Titillium Web", sans-serif;
      font-size: 186px;
      font-weight: 900;
      margin: 0px;
      text-transform: uppercase;
      background: url("../helpers/images/text.png");
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-size: cover;
      background-position: center;
    }
  }
  & h2 {
    font-family: "Titillium Web", sans-serif;
    font-size: 26px;
    font-weight: 700;
    margin: 0;
  }
  & p {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0px;
    text-transform: uppercase;
  }
  & a {
    font-family: "Titillium Web", sans-serif;
    display: inline-block;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
    border: none;
    background: #3c9b83;
    padding: 10px 40px;
    font-size: 14px;
    font-weight: 700;
    border-radius: 1px;
    margin-top: 15px;
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
    &:hover {
      color: #fff;
      text-decoration: none;
    }
  }
}

@media only screen and (max-width: 767px) {
  .notfound .notfound-404 {
    height: 110px;
    line-height: 110px;
    & h1 {
      font-size: 120px;
    }
  }
}
