.suggesstion-box {
  & .suggesstion-list {
    list-style: none;
    padding: 0;
    position: absolute;
    z-index: 100000;
    width: 94%;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    background-color: #fff;
    max-height: 400px;
    overflow-y: auto;
    & li {
      padding: 10px;
      border-bottom: #bbb9b9 1px solid;
      cursor: pointer;
      &:hover {
        background-color: rgba(0, 0, 0, 0.075);
      }
    }
  }
}
