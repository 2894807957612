@import "../helpers/scss/config";
.incall {
  & .bg-image {
    /* Add the blur effect */
    filter: blur(8px);
    -webkit-filter: blur(8px);

    /* Full height */
    height: 100%;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  & .text-overlay {
    position: absolute;
    top: 0;
    left: 43%;
    text-align: center;

    & .text {
      color: $light-color;
      background-color: rgba(60, 155, 131, 0.6);
      width: fit-content;
      margin: auto;
      padding: 2px;
      border-radius: 3px;
    }
  }

  & .controlsButtons {
    background-color: $light-color;
    border-radius: 50px;
    padding: 5px 0;
    display: flex;
    justify-content: space-around;

    & img {
      // width: 35%;
      cursor: pointer;
    }
  }
}

.call-animation {
  background: #fff;
  width: 135px;
  height: 135px;
  position: relative;
  margin: 0 auto;
  border-radius: 100%;
  animation: play 2s ease infinite;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  & img {
    width: 135px;
    height: 135px;
    border-radius: 100%;
    border: solid 5px #fff;
    position: absolute;
    left: 0px;
    top: 0px;
  }
}

@keyframes play {
  0% {
    transform: scale(1);
  }
  15% {
    box-shadow: 0 0 0 5px rgba(60, 155, 131, 0.6);
  }
  25% {
    box-shadow: 0 0 0 10px rgba(60, 155, 131, 0.6),
      0 0 0 20px rgba(60, 155, 131, 0.4);
  }
  25% {
    box-shadow: 0 0 0 15px rgba(60, 155, 131, 0.6),
      0 0 0 30px rgba(60, 155, 131, 0.4);
  }
}

@media (max-width: 575.98px) {
  .incall {
    & .text-overlay {
      left: 22%;
    }
    & .controlsButtons {
      & img {
        width: 30%;
        cursor: pointer;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .incall {
    & .text-overlay {
      left: 32%;
    }
    & .controlsButtons {
      & img {
        width: 30%;
        cursor: pointer;
      }
    }
  }
}
