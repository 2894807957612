@import "../helpers/scss/config";

.main-left-sidebar {
  float: left;
  // margin-top: 100px;

  & .user_profile {
    float: left;
    width: 100%;
    // background-color: #fff;
    margin-bottom: 20px;
    text-align: center;
    // margin-top: -40px;

    & .user-pro-img {
      float: left;
      width: 100%;
      text-align: center;
      margin-bottom: 12px;
      // margin-top: -95px;
      position: relative;

      & img {
        -webkit-border-radius: 100px;
        -moz-border-radius: 100px;
        -ms-border-radius: 100px;
        -o-border-radius: 100px;
        border-radius: 100px;
        float: none;
        // border: 5px solid #fff;
        width: 80px;
        height: 80px;
      }
      & .add-dp {
        position: absolute;
        top: 54px;
        left: 57%;
        & input[type="file"] {
          opacity: 0;
          padding: 10px 0 9px;
          display: none;
        }
        & svg {
          bottom: 0;
          // font-size: 14px;
          box-shadow: none;
          position: relative;
          border-radius: 50px;
          width: 25px;
          height: 25px;
          text-align: center;
          background: $light-color;
          padding: 5px;
          color: #ffff;
          // left: -45px;
          // top: 145px;
          filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
          &:hover {
            // font-size: 14px;
            // border: 2px solid #fff;
            background: $light-color;
            // padding: 11px;
            cursor: pointer;
          }
        }
      }
      & .rattingSpan {
        background-color: $light-color;
        border: 1px solid $primary-color;
        font-size: 14px;
        color: $primary-color;
        display: inline-block;
        padding: 3px 10px;
        border-radius: 30px;
        margin-left: 5px;
      }
    }
    & .user_pro_status {
      float: left;
      width: 100%;
      color: $primary-color;
      // border-bottom: 1px solid #e5e5e5;
      // padding-bottom: 27px;

      & h6 {
        font-weight: 600;
        text-transform: capitalize;
      }
    }
  }
}

.lightBg {
  background-color: $light-color;
  filter: drop-shadow(0px 3px 5px rgba(195, 195, 195, 1));
  border-radius: 50px;
  padding: 18px;
}
.title-bar {
  display: flex;
  justify-content: center;
  width: 100%;

  & .title {
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: bold;
    color: $primary-color;
  }
  & .buttons {
    margin-left: auto;
  }
}
.main-ws-sec {
  & .nav-tabs {
    margin-bottom: 45px;
    margin-top: 20px;
    border-bottom: 0;
    justify-content: space-around !important;

    & .nav-link {
      // color: #b2b2b2;
      // font-weight: 400;
      // font-size: 16px;
      border: 1px solid transparent !important;
      background-color: #f2fefb !important;
      border-radius: 50px !important;
      filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
      color: $primary-color;
      text-align: center;
      width: 15%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      & img,
      svg {
        margin: 0 auto;
      }
      &.active {
        background-color: $primary-color !important;
        color: $light-color;
        .unActiveImage {
          display: none;
        }
        .activeImage {
          display: block;
        }
      }
      & .unActiveImage {
        display: block;
      }
      & .activeImage {
        display: none;
      }
    }
  }

  & .tab-content > .tab-pane {
    float: left;
    width: 100%;
    // background-color: #fff;
    padding: 30px 20px;
    // border-left: 1px solid #e4e4e4;
    // border-bottom: 1px solid #e4e4e4;
    // border-right: 1px solid #e4e4e4;
    border: none;
    color: $primary-color;
    margin-bottom: 20px;

    & .helped {
      & .helpedTitle {
        color: $primary-color;
        font-variant-caps: all-petite-caps;
        font-weight: bold;
      }
      & .helpedNumber {
        // position: absolute;
        // top: 49%;
        // left: 49.5%;
        color: $primary-color;
        font-size: 200px;
        margin: 0;
      }
    }

    .card.notification {
      background: #f2fefb;
      margin-bottom: 30px;
      transition: 0.5s;
      border-radius: 0.5rem;
      border-color: transparent;
      display: inline-block;
      position: relative;
      width: 100%;
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);
      -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);
      -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);
      & .body {
        padding: 10px 20px;
      }
    }
  }

  & .user-profile-ov {
    & button.addExpert {
      background-color: $primary-color;
      border-color: $primary-color;
      color: #fff;
      float: right;
      margin-bottom: 0.25em;
      border-radius: 40px;
      &.disabled {
        pointer-events: none;
      }
    }
    & .experts {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      & .expertsSpan {
        width: 30%;
        text-align: center;
        margin-bottom: 3%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        & p {
          display: inline-block;
          margin: 0;
          cursor: pointer;
        }
        & svg {
          float: right;
          position: relative;
          right: -96%;
          bottom: 90%;
          cursor: pointer;
          color: #707070;
        }
      }
    }
    // & ul {
    //   float: left;
    //   width: 100%;
    //   margin: 0;
    //   padding: 0;
    //   & li {
    //     // display: inline-block;
    //     margin-bottom: 10px;
    //     padding: 0 2px;
    //     list-style: none;
    //     border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    //     &:last-child {
    //       margin-bottom: 0;
    //       border-bottom: 0;
    //     }
    //     & h4 {
    //       font-size: 16px;
    //       color: #000;
    //       font-weight: 600;
    //       margin-bottom: 0px;
    //     }
    //     & small {
    //       color: #686868;
    //     }
    //     & p {
    //       font-size: 15px;
    //       line-height: 24px;
    //     }

    //     // & span {
    //     //   display: inline-block;
    //     //   color: #333333;
    //     //   font-size: 14px;
    //     //   font-weight: 600;
    //     //   background-color: #e5e5e5;
    //     //   -webkit-border-radius: 30px;
    //     //   -moz-border-radius: 30px;
    //     //   -ms-border-radius: 30px;
    //     //   -o-border-radius: 30px;
    //     //   border-radius: 30px;
    //     //   padding: 10px 15px;
    //     // }
    //   }
    // }
  }
}

.onoffswitch {
  position: relative;
  width: 56px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  & .onoffswitch-checkbox {
    display: none;

    &:checked + .onoffswitch-label .onoffswitch-inner {
      margin-left: 0;
    }
    &:checked + .onoffswitch-label .onoffswitch-switch {
      right: 0px;
    }
  }

  & .onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid #ffffff;
    border-radius: 20px;
  }

  & .onoffswitch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 22px;
      padding: 0;
      line-height: 22px;
      font-size: 12px;
      color: black;
      font-family: Trebuchet, Arial, sans-serif;
      font-weight: bold;
      box-sizing: border-box;
    }
    &:before {
      content: "ON";
      padding-left: 6px;
      background-color: $primary-color;
      color: #ffffff;
    }
    &:after {
      content: "OFF";
      padding-right: 6px;
      background-color: #e85764;
      color: #ffffff;
      text-align: right;
    }
  }
  & .onoffswitch-switch {
    display: block;
    width: 16px;
    margin: 5px;
    background: #ffffff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 30px;
    border: 2px solid #ffffff;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }
}

.modal-dialog {
  -webkit-transform: translate(0, -50%) !important;
  -o-transform: translate(0, -50%) !important;
  transform: translate(0, -50%) !important;
  top: 50% !important;
  margin: 0 auto !important;

  & .modal-content {
    background-color: #f9f9f9;
    border-radius: 39px;

    & label {
      color: $primary-color;
      font-weight: bold;
    }

    & .form-control {
      border-radius: 20px;
      background-color: #ffffff;
      border-color: #dfdfdf;
      color: $primary-color;
      &:disabled {
        background-color: #f9f9f9;
        border: none;
        color: #000 !important;
      }
    }
  }
  & .modal-footer {
    justify-content: center;
    border: none;
  }
}

@media (max-width: 575.98px) {
  .main-left-sidebar {
    float: none;
    // margin-top: 120px;
  }
  .add-pic-box [type="file"] + label {
    font-size: 12px;
    padding: 6px 10px;
  }
}

@media (max-width: 767.98px) {
  .main-left-sidebar {
    & .user_profile {
      margin-bottom: 10px;
    }
  }
  .main-ws-sec {
    & .nav-tabs {
      margin-top: 10px;
    }
    & .tab-content > .tab-pane {
      padding: 10px 0px;
      & .card.notification {
        margin-bottom: 15px;
        font-size: 14px;
        & img {
          // width: 70%;
        }
      }
      & .helped {
        & .helpedNumber {
          font-size: 170px;
        }
        & img {
          width: 100%;
        }
        & .text-right.col,
        & .text-left.col {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }
    & .user-profile-ov {
      & .experts {
        & .expertsSpan {
          width: 48%;
          padding: 10px;
          & svg {
            bottom: 86%;
            right: -89%;
          }
        }
      }
    }
  }
}
@media (max-width: 767.98px) {
  .main-ws-sec {
    & .nav-tabs {
      & .nav-link {
        width: auto;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .main-ws-sec {
    & .nav-tabs {
      & .nav-link {
        width: 20%;
      }
    }
  }
}
