@import "../helpers/scss/config";
.Android {
  background-image: url(../helpers/images/iosbg.png);
  min-height: 100vh;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  background-position: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nasalization {
  font-family: nasalization;
}
