.search {
  & .input-group {
    background-color: rgb(255, 255, 255);
    border-radius: 0.3rem;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid #ced4da;
  }
  & .form-control {
    &:focus {
      border: none;
      box-shadow: none;
    }
    &::placeholder {
      font-size: 0.95rem;
      color: #aaa;
      font-style: italic;
    }
  }
  & button.disabled {
    cursor: default;
    pointer-events: none;
  }
}

@media (max-width: 767.98px) {
  .search {
    & .bigcircle {
      width: 70%;
    }
    & .btn.btn-primary {
      font-size: 14px;
      padding: 12px 6px;
      margin: auto;
    }
    & .suggesstion-box .suggesstion-list {
      width: 92%;
      max-height: 340px;
    }
  }
}
