@import "../helpers/scss/config";
.wallet {
  // background: linear-gradient(to bottom, #3b9a80 0%, #393939 100%);
  height: 100%;
  border-radius: 39px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: $primary-color;
  background: linear-gradient(180deg, $primary-color 0%, $light-color 100%);
  & h3 {
    color: $light-color;
    font-weight: bold;
  }
  & h1 {
    // position: absolute;
    // top: 45%;
    // left: 47%;
    margin: 0;
    font-size: 100px;
    color: $primary-color;
    background: linear-gradient(90deg, $light-color 50%, $light-color 50%);
    border: 3px solid $gray-color;
    border-radius: 50%;
    width: fit-content;
    margin: auto;
    padding: 50px;
  }

  & img {
    border-radius: 50%;
    box-shadow: 6px 1px 6px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 6px 1px 6px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 6px 1px 6px 0px rgba(0, 0, 0, 0.25);
  }

  & form input,
  & form input:focus,
  & form select {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    padding: 0 15px 0 40px !important;
    color: $primary-color !important;
    font-size: 16px !important;
    border: 2px solid $primary-color !important;
    border-radius: 25px !important;
    height: 50px !important;
    // height: 40px;
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $primary-color !important;
      opacity: 1 !important; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $primary-color !important;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $primary-color !important;
    }
  }

 

  & form button {
    color: #fff;
    font-size: 16px;
    background-color: $primary-color;
    padding: 10px 60px;
    border: 0;
    border-radius: 20px;
    font-weight: 500;
    // margin-top: 30px;
    &:hover {
      background-color: $primary-color;
    }
  }
   & button{
    border-radius: 39px !important;
  }

  & svg {
    position: absolute;
    top: 50px;
    left: 50px;
    cursor: pointer;
    color: #fff;
    font-size: 30px;
  }
}

@media (max-width: 767.98px) {
  .wallet {
    & h1 {
      font-size: 170px;
    }
    & .text-right.col,
    & .text-left.col {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
