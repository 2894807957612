@import "../helpers/scss/config";
.navbar {
  position: absolute !important;
  &.greenBg {
    background-color: $primary-color;
  }
  & .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.75);
    &:hover,
    &.active {
      color: rgba(255, 255, 255, 1);
    }
  }
  & .navbar-brand {
    width: 27%;
  }
  & .thumbnail-image {
    margin-right: 10px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
    border-radius: 100px;
  }
  & .dropdown-menu {
    & .dropdown-header {
      color: #000;
      font-weight: 600;
    }
    & label,
    & a {
      font-size: 14px;
    }
  }

  & .btn {
    font-weight: bold;
    font-family: Arial;
    background-color: $light-color;
    color: $primary-color;
    border: solid 3px $light-color;
    border-radius: 20px;
    padding: 8px 25px;
  }
  & .btn:hover,
  & .btn.active {
    background-color: $primary-color;
    color: $light-color;
  }

  // & .nav-item.dropdown a {
  //   color: $primary-color !important;
  // }
}

@media (max-width: 991px) {
  .navbar {
    & .navbar-brand {
      width: 45%;
    }
  }
}

@media (max-width: 576px) {
  .navbar {
    & .navbar-brand {
      width: 78%;
    }
  }
}
.navbar-dark {
  & .navbar-toggler {
    color: rgba(60, 155, 131, 1) !important;
    border-color: rgba(60, 155, 131, 1) !important;
  }
  & .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(60,155,131, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>") !important;
  }
}
