@import "../helpers/scss/config";

header {
  background-image: url("../helpers/images/homebackground.jpg");
  background-color: #cccccc; /* Used if the image is unavailable */
  height: 30vh; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  & .header {
    height: 100%;
    & h1 {
      color: $light-color;
      font-family: nasalization;
    }
    & .btn.btn-primary.buttonHeader {
      font-size: 20px;
      font-weight: bold;
      background: linear-gradient(to bottom right, #50a492 0%, #285249 100%);
      border: none;
      border-radius: 12px;
      letter-spacing: 2px;
      text-decoration: none;
      &:hover {
        text-decoration: none;
        color: $primary-color;
        background: $light-color;
      }
    }
    & small,
    a,
    h2,
    p {
      color: $light-color;
    }
    & a {
      text-decoration: underline;
    }
  }
}
@media (max-width: 991.98px) {
  header {
    & img.bubbles {
      top: 66vh !important;
      width: 15vw !important;
      left: 3% !important;
    }
  }
}

@media (max-width: 767.98px) {
  header {
    & .header {
      p {
        font-size: 14px;
      }
    }
  }
}
