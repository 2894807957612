@import "../helpers/scss/config";
.successes-singup {
  padding-top: 25vh;
  & img {
    width: 30%;
  }
  & h2,
  p,
  small {
    color: $primary-color;
  }
  & .btn.btn-primary {
    background-color: $primary-color;
    border-color: $primary-color;
  }
}
