.messages {
  & .profile-page .profile-header .profile-image img {
    width: 60px;
    height: 60px;
  }

  & .card {
    & h6 {
      word-wrap: break-word;
    }
    & h5 {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    & small {
      // float: right;
      color: #707070;
    }
  }
}
