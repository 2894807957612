@import "../helpers/scss/config";

.contactUs {
  height: 95.6vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  & h1 {
    color: $primary-color;
    font-family: nasalization;
    // font-size: 40px;
  }
  & input,
  & textarea {
    background-color: #f2fefb;
    border-color: transparent;
  }
  & button {
    padding: 5px 35px;
  }
}
