@import "../helpers/scss/config";
.profile-page {
  & .card.favourite {
    background: #fff;
    & img {
      width: 37px !important;
      height: 37px !important;
    }
  }
}

@media (max-width: 575.98px) {
  .profile-page {
    & .card.favourite {
      & img {
        width: 46px !important;
        height: 46px !important;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .profile-page {
    & .card.favourite {
      & img {
        width: 42px !important;
        height: 42px !important;
      }
    }
  }
}
