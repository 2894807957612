@import "../helpers/scss/config";
footer {
  & .firstSection {
    background-color: $primary-color;
    color: $light-color;
    font-size: 14px;
    padding: 0.5% 0;

    a{
      color: $light-color;
    }
  }
}
