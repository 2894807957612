@import "../helpers/scss/config";
.ReferralCode {
  // background: linear-gradient(to bottom, #3b9a80 0%, #393939 100%);
  height: 100%;
  border-radius: 39px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  & h3 {
    color: $primary-color;
    font-weight: bold;
  }
  & h1 {
    margin: 0;
  }

  & img {
    width: 45%;
  }
  & p {
    color: #565656;
  }
}
