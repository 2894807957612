.sign_in_sec {
  & .col-12 .sn-field svg {
    left: 6%;
  }
  & .col-12 .sn-field svg.eye {
    left: auto;
    right: 6%;
    cursor: pointer;
  }
}

@media (max-width: 575.98px) {
  // .sign_in_sec {
  //   & .col-12 .sn-field svg {
  //     left: 8%;
  //   }
  // }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .sign_in_sec {
    & .col-12 .sn-field svg {
      left: 4%;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  // .sign_in_sec {
  //   & .col-12 .sn-field svg {
  //     left: 8.5%;
  //   }
  // }
}

@import "../helpers/scss/config";

.singUpBg {
  background-image: url("../helpers/images/signupbackground.png");
  background-color: #cccccc; /* Used if the image is unavailable */
  height: 73vh; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}
