@import "../helpers/scss/config";
.editprofile {
  & h4 {
    color: $primary-color;
  }
  & .lightBg {
    background-color: #fff;
    filter: drop-shadow(0px 3px 5px #c3c3c3);
    border-radius: 50px;
    padding: 18px;
  }
  & svg {
    display: inline-block;
    position: absolute;
    left: 6%;
    color: #666;
    font-size: 16px;
    transform: translateY(-218%);
  }
  & .sn-field {
    position: relative;
    & svg.eye {
      left: auto;
      right: 3%;
      cursor: pointer;
    }
  }

  & .form-control {
    border: none;
    border-bottom: 1px solid #d8d8d8;
    color: $primary-color;
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #d8d8d8 !important;
      opacity: 1 !important; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #d8d8d8 !important;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #d8d8d8 !important;
    }
  }
}
