@import "../helpers/scss/config";
.sidebar {
  background-color: $primary-color;
  border-radius: 80px;
  text-align: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  & .nav-item {
    margin-top: 1.15rem;
    margin-bottom: 1.15rem;
    color: $light-color;
    width: 100%;
    display: block;
    & a {
      color: #caeee6;
    }
    &.active {
      border-right: 6px solid $light-color;
      margin-right: 0.5rem;
      & a {
        color: $light-color;
      }
    }
  }

  & .main-left-sidebar {
    float: none;
    // margin-top: 100px;

    & .user_profile {
      float: none;
      width: 100%;
      // background-color: #fff;
      margin-bottom: 20px;
      text-align: center;
      // margin-top: -40px;

      & .user-pro-img {
        float: none;
        width: 100%;
        text-align: center;
        margin-bottom: 12px;
        // margin-top: -95px;
        position: relative;

        & img {
          -webkit-border-radius: 100px;
          -moz-border-radius: 100px;
          -ms-border-radius: 100px;
          -o-border-radius: 100px;
          border-radius: 100px;
          float: none;
          // border: 5px solid #fff;
          width: 80px;
          height: 80px;
        }
        & .add-dp {
          position: absolute;
          top: 54px;
          left: 55%;
          & input[type="file"] {
            opacity: 0;
            padding: 10px 0 9px;
            display: none;
          }
          & svg {
            bottom: 0;
            // font-size: 14px;
            box-shadow: none;
            position: relative;
            border-radius: 50px;
            width: 25px;
            height: 25px;
            text-align: center;
            background: $primary-color;
            padding: 5px;
            border: 1px solid #fff;
            // left: -45px;
            // top: 145px;
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
            &:hover {
              // font-size: 14px;
              // border: 2px solid #fff;
              background: $primary-color;
              // padding: 11px;
              cursor: pointer;
            }
          }
        }
      }
      & .user_pro_status {
        float: none;
        color: $light-color;
        & h6 {
          font-weight: bold;
          text-transform: capitalize;
        }
      }
    }
  }
}

@media (max-width: 767.98px) {
  .sidebar {
    & .nav-item {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
}
