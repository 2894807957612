@import "../helpers/scss/config";
.tsparticles-canvas-el {
  background-color: $primary-color;
}

.sign_in_sec {
  padding: 4.2vh 7.5vh;
  backdrop-filter: blur(17px);
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 46px;

  & h2 {
    font-weight: 600;
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 10px;
    color: $light-color;
    text-align: center;
    font-family: nasalization;
  }
  & .sn-field {
    // margin-bottom: 20px;
    position: relative;
    & svg {
      display: inline-block;
      position: absolute;
      // left: 19.5%;
      left: 6%;
      color: #666;
      font-size: 16px;
      -webkit-transform: translateY(-218%);
      -moz-transform: translateY(-218%);
      -ms-transform: translateY(-218%);
      -o-transform: translateY(-218%);
      transform: translateY(-218%);
    }
    svg.eye {
      left: auto;
      right: 6%;
      cursor: pointer;
    }
  }

  & form input,
  & form input:focus,
  & form select {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    padding: 0 15px 0 40px !important;
    color: $primary-color !important;
    font-size: 16px !important;
    border: 2px solid $primary-color !important;
    border-radius: 25px !important;
    height: 50px !important;
    // height: 40px;
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $primary-color !important;
      opacity: 1 !important; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $primary-color !important;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $primary-color !important;
    }
  }

  & form button {
    color: #fff;
    font-size: 16px;
    background-color: $primary-color;
    padding: 10px 60px;
    border: 0;
    border-radius: 20px;
    font-weight: 500;
    // margin-top: 30px;
    &:hover {
      background-color: $primary-color;
    }
  }
  & small.athor-option {
    color: $light-color;
    & a {
      color: $light-color;
      text-decoration: underline;
      font-weight: bold;
    }
  }

  & .checky-sec {
    margin-top: 10px;
    & .fgt-sec {
      display: inline-block;

      & small {
        display: inline-block;
        color: $light-color;
        font-size: 14px;
        font-weight: 500;
        margin-left: 10px;
        position: relative;
        bottom: 3px;
      }
      & input[type="checkbox"] {
        display: none;
      }

      & label {
        display: inline-block;
      }

      & input[type="checkbox"] + label span {
        cursor: pointer;
        display: inline-block;
        width: 15px;
        height: 15px;
        position: relative;
        border: 1px solid $light-color;
        -webkit-border-radius: 100px;
        -moz-border-radius: 100px;
        -ms-border-radius: 100px;
        -o-border-radius: 100px;
        border-radius: 100px;
      }

      & input[type="checkbox"] + label span:before {
        content: "";
        width: 7px;
        height: 7px;
        -webkit-border-radius: 100px;
        -moz-border-radius: 100px;
        -ms-border-radius: 100px;
        -o-border-radius: 100px;
        border-radius: 100px;
        font-size: 8px;
        color: #fff;
        opacity: 0;
        visibility: hidden;
        background-color: $light-color;
        position: absolute;
        font-family: fontawesome;
        top: 50%;
        left: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
        -moz-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
        -o-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
      }

      & input[type="checkbox"]:checked + label span:before {
        opacity: 1;
        visibility: visible;
      }
    }
    & > a {
      float: right;
      color: $light-color;
      font-size: 14px;
      font-weight: 500;
    }
  }
  & .checky-sec.st2 .fgt-sec small {
    font-size: 13px;
    width: 90%;
    line-height: 20px;
  }
}

.singInBg {
  background-image: url("../helpers/images/signinbackground.png");
  background-color: #cccccc; /* Used if the image is unavailable */
  height: 73vh; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  justify-content: center;
}

@media (max-width: 991px) {
  .tsparticles-canvas-el {
    min-height: 1100px;
  }
  .wrapper .sign-in-page {
    width: 89%;
  }
  .cmp {
    &-info {
      padding: 40px 20px 0;
    }
    &-logo {
      margin-bottom: 0;
      padding-left: 0;
    }
  }

  .sign-control {
    text-align: left;
    padding-left: 20px;
    padding-right: 0;
    // margin-bottom: 30px;
  }

  // .sign_in_sec {
  //   padding: 0 20px;
  // }
}

@media (max-width: 575.98px) {
  .sign_in_sec {
    padding: 4.2vh 3.5vh;
    // & .sn-field svg {
    //   left: 12%;
    // }
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .sign_in_sec {
    & .sn-field svg {
      left: 4%;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  // .sign_in_sec {
  //   & .sn-field svg {
  //     left: 21.5%;
  //   }
  // }
}
